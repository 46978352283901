<template>
    <div>
        <div class="card">
            <Toolbar class="mb-4">
                <template #start>
                    <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                    <!--
                        <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
                    -->
                </template>

                <template #end>
                    <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 inline-block" />
                    <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                </template>
            </Toolbar>

            <DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" 
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
						<h5 class="mb-2 md:m-0 p-as-md-center">Mano de obra</h5>
						<span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                        </span>
					</div>
                </template>
                <!--
                    <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                -->

                <Column field="name" header="Nombre" :sortable="true" style="min-width:12rem">
                      <template #body="slotProps">
                        <div>{{ slotProps.data.name }}</div>
                        <span class="cell_subtitle" v-if="slotProps.data.description">{{slotProps.data.description}}</span>
                        
                    </template>
                </Column>


                <Column field="price" header="Minuto/Hora U$D" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.valor)}}  / {{formatCurrency(slotProps.data.valor * 60)}}  
                    </template>
                </Column>
                <Column field="price" header="Minuto/Hora AR$" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{formatCurrencyARS (slotProps.data.valor * dolarValue )}} / {{formatCurrencyARS ((slotProps.data.valor * dolarValue) * 60 )}}
                    </template>
                </Column>
                
                <Column :exportable="false" style="min-width:8rem">
                    <template #body="slotProps">
                        
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
                    </template>
                </Column>

            </DataTable>
        </div>

        <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Costo mano de obra" :modal="true" class="p-fluid">            
            <div class="field">
                <label for="name">Nombre</label>
                <InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
                <small class="p-error" v-if="submitted && !product.name">Nombre es requerido.</small>
            </div>
            <div class="field">
                <label for="descripcion">Descripción</label>
                <Textarea id="descripcion" v-model="product.description" required="true" rows="3" cols="20" />
            </div>


            <div class="formgrid grid">
                <div class="field col">
                    <label for="price">Valor</label>
                    <InputNumber id="price" v-model="product.valor" mode="currency" currency="USD" locale="en-US" />
                </div>
                
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Seguro quieres eliminar <b>{{product.name}}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
                <Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
        </Dialog>


	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import ProductService from './../services/crud.service';
import CrudService from './../services/crud.service';

export default {
    setup() {

        onMounted(() => {
            CrudService.getCRUD('mano-de-obra','mano-de-obra').then(data => products.value = data);
            CrudService.getCRUD("configuraciones").then(response => {
                dolarValue.value = response.dolarvalue;                 
            });
        })

        const toast = useToast();
        const dt = ref();
        const products = ref();
        const productDialog = ref(false);
        const deleteProductDialog = ref(false);
        const deleteProductsDialog = ref(false);
        const product = ref({});
        const dolarValue = ref()
        
        //const productService = ref(new ProductService());
        const selectedProducts = ref();
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });
        const submitted = ref(false);
        const statuses = ref([
	     	{label: 'EN STOCK', value: 'instock'},
	     	{label: 'BAJO STOCK', value: 'lowstock'},
	     	{label: 'SIN STOCK', value: 'outofstock'}
        ]);

        const estados = ref({
            'INSTOCK': "EN STOCK",
            'LOWSTOCK': "BAJO STOCK",
            'OUTOFSTOCK': "SIN STOCK"
        });
        
        const formatUnity = (value) => {
            
           switch(value) {
                case 1:
                    return "unidad";
                case 2:
                    return "kg";
                case 3:
                    return "mt";
                case 4:
                    return "lt";
                case 5:
                    return "m2";
                case 6:
                    return "mtl";
                case 7:
                    return "gr";
                case 8:
                    return "juego";
                default:
                    return "";
            }

        };

        const formatCurrency = (value) => {
            if(value)
				return 'U$D ' + value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
        };
        const formatCurrencyARS  = (value) => {
            if(value)
				return 'AR$ ' + value.toLocaleString('es-AR', {style: 'currency', currency: 'ARS'});
			return;
        };
        const openNew = () => {
            product.value = {};
            submitted.value = false;
            productDialog.value = true;
        };
        const hideDialog = () => {
            productDialog.value = false;
            submitted.value = false;
        };
        const saveProduct = () => {
            submitted.value = true;

			if (product.value.name.trim()) {
                if (product.value.id) {
                    CrudService.updateCRUD('mano-de-obra','mano-de-obra', product.value).then((data) => {
                        let validationErrors = [];
                        if (data.status == 400) { 
                          this.displayErrors(data.data);
                        } else {
                            products.value[findIndexById(data.data.id)] = data.data;
                            //products.value[findIndexById(product.value.id)] = product.value;
                            toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
                        }
                    });
                } else {
                    CrudService.createCRUD('mano-de-obra','mano-de-obra', product.value).then((data) => {                          
                        let validationErrors = [];
                        if (data.status == 400) {
                          this.displayErrors(data.data);                          
                        } else {
                            product.value.id = createId();
                            products.value.push(data.data);
                            toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
                        }
                    });
                }
                productDialog.value = false;
                product.value = {};
            }
        };
        const editProduct = (prod) => {
            product.value = {...prod};
            productDialog.value = true;
        };
        const confirmDeleteProduct = (prod) => {
            product.value = prod;
            deleteProductDialog.value = true;
        };
        const deleteProduct = () => {

            // console.log(product.value)
        
            // products.value = products.value.filter(val => val.id !== product.value.id);
            // deleteProductDialog.value = false;
            // product.value = {};
            // toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});

            CrudService.deleteCRUD('mano-de-obra', 'mano-de-obra', product.value.id).then(
                (data) => {
                    products.value.splice(findIndexById(data.data.id), 1);
                    deleteProductDialog.value = false;
                    toast.add({severity: "success",summary: "Éxito!",detail: "Eliminado correctamente.",life: 3000,});
                    //$toast.add({severity: "success",summary: "Éxito!",detail: "Eliminado correctamente.",life: 3000,});
                }
            );
        };
        const findIndexById = (id) => {
            let index = -1;
            for (let i = 0; i < products.value.length; i++) {
                if (products.value[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;
        };
        const createId = () => {
            let id = '';
            var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for ( var i = 0; i < 5; i++ ) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        }
        const exportCSV = () => {
            dt.value.exportCSV();
        };
        const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        };
        const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        };

        return { dt, products, productDialog, deleteProductDialog, deleteProductsDialog, product, 
            selectedProducts, filters, submitted, statuses, formatCurrency, formatCurrencyARS, openNew, hideDialog, saveProduct, editProduct, formatUnity,
            confirmDeleteProduct, deleteProduct, findIndexById, createId, exportCSV, confirmDeleteSelected, deleteSelectedProducts, estados, dolarValue}


    }
}
</script>

<style lang="scss" scoped>
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.cell_subtitle {
    font-size: 0.8em;
    color: #8c8c8c;
}

.product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 50px;
    margin: 0 auto 2rem auto;
    display: block;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
        
		.p-button {
            margin-bottom: 0.25rem;
        }
	}
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-instock {
    background: #c8e6c9;
    color: #256029;
}

.product-badge.status-lowstock {
    background: #feedaf;
    color: #8a5340;
}

.product-badge.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
}
</style>